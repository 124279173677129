




































import {namespace} from 'vuex-class';
import {fileStoreActions, fileStoreGetter} from '@/stores/file.store';
import {Label} from '@/models/DbFile';
import {Component, VModel, Vue, Watch} from 'vue-property-decorator';

const FileStore = namespace('file');

@Component({
  components: {},
})
export default class LabelComponent extends Vue {
  @FileStore.Action(fileStoreActions.CREATE_LABEL_ACTION)
  private createLabelAction!: (label: Label) => Promise<Label>;

  @FileStore.Action(fileStoreActions.LOAD_LABELS_ACTION)
  private loadLabelsAction!: (customerId: string) => Promise<Label[]>;

  @FileStore.Action(fileStoreActions.DELETE_LABEL_ACTION)
  private deleteLabelAction!: (id: string) => Promise<void>;

  @FileStore.Getter(fileStoreGetter.LABELS)
  private _labels!: Label[];

  @VModel({required: true})
  private label!: string | Label;

  private get labels() {
    return this._labels;
  }

  public async mounted() {
    await this.loadLabelsAction(this.$route.params.companyId);
  }

  private async createLabel() {
    (this.$refs.labelBox as any).blur();
    try {
      this.$nextTick(async () => {
        if (this.label) {
          const name = typeof this.label === 'string' ? this.label : this.label.name;
          if (name.trim()) {
            await this.createLabelAction({name, companyId: this.$route.params.companyId});
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  private async deleteLabel(item: Label) {
    try {
      await this.deleteLabelAction(item.id!);
      this.$notifySuccessSimplified('MANAGE.LABEL_DELETE_MESSAGE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('MANAGE.LABEL_DELETE_MESSAGE.ERROR');
    } finally {
      await this.loadLabelsAction(this.$route.params.companyId);
    }
  }

  private updateLabel(input: string) {
    this.$emit('updateLabel', input);
  }
}
